const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

export const LoginValidation = {
  email: {
    required: "この項目は必須です",
    pattern: {
      value: EMAIL_REGEXP,
      message: "メールアドレスの形式が間違っています"
    }
  },
  password: {
    required: "この項目は必須です"
  }
} as const;
