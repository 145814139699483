import colors from "colors";
import Text from "components/Atoms/Text";
import Header from "components/Molecules/Header";
import Footer from "components/Molecules/HomeFooter";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const ErrorPage = () => {
  return (
    <>
      <Header />
      <Container>
        <img
          src={`${process.env.PUBLIC_URL}/images/error/404.png`}
          alt="404"
          width={350}
          height={150}
        />
        <TextDiv>
          <Text size={32} color="white">
            お探しのページが見つかりませんでした。
          </Text>
        </TextDiv>
        <div>
          <Text size={16} color="white">
            ご指定のページは一時的にアクセスできない状況にあるか、
          </Text>
          <Text size={16} color="white">
            移動もしくは削除された可能性があります。
          </Text>
        </div>
        <LinkText to="/home">トップページへ戻る</LinkText>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  background-color: ${colors.green};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 15px;
`;

const TextDiv = styled.div`
  padding: 20px 0;
`;

const LinkText = styled(Link)`
  color: ${colors.white};
  padding: 40px;
`;

export default ErrorPage;
