import { RankingQuery, useRankingQuery } from "api/graphql";
import colors from "colors";
import ErrorDialog from "components/Molecules/ErrorDialog";
import Footer from "components/Molecules/Footer";
import Ranking from "components/Organisms/Ranking/RankingContent";
import Transition from "components/Organisms/Ranking/TransitionContent";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

enum Tab {
  Ranking = 0,
  Transition
}

const RankingPage = () => {
  const [data, setData] = useState<RankingQuery>({
    getUser: { name: "", todayRanking: null },
    firstRanking: [],
    secondRanking: [],
    thirdRanking: [],
    getMonthlyTransitionRankings: []
  });
  const [isOpenDiglog, setDialog] = useState(false);
  const [isOpenTab, setTab] = useState(Tab.Ranking);
  const { error } = useRankingQuery({
    onCompleted: data => {
      if (data) {
        setData(data);
      }
    }
  });

  useEffect(() => {
    // 通信エラー
    if (error?.networkError) {
      setDialog(true);
    }
  }, [error]);

  const handleSetTab = (v: Tab) => () => {
    setTab(v);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  if (error?.graphQLErrors) {
    return <Redirect to="/login" />;
  }

  return (
    <div>
      <ErrorDialog isOpen={isOpenDiglog} onClose={handleCloseDialog} />
      <TabArea>
        <TabButton
          selected={isOpenTab === Tab.Ranking ? true : false}
          onClick={handleSetTab(Tab.Ranking)}
        >
          ランキング
        </TabButton>
        <TabButton
          selected={isOpenTab === Tab.Transition ? true : false}
          onClick={handleSetTab(Tab.Transition)}
        >
          推移グラフ
        </TabButton>
      </TabArea>
      {isOpenTab === Tab.Ranking && (
        <Ranking
          getUser={data.getUser}
          firstRanking={data.firstRanking}
          secondRanking={data.secondRanking}
          thirdRanking={data.thirdRanking}
        />
      )}
      {isOpenTab === Tab.Transition && (
        <Transition
          getMonthlyTransitionRankings={data.getMonthlyTransitionRankings}
        />
      )}
      <Footer />
    </div>
  );
};

const TabArea = styled.div`
  display: flex;
  justify-content: center;
  padding: 44px 20px 15px 20px;
  background-color: ${colors.green};
  margin-bottom: 10px;
`;

const TabButton = styled.div<{ selected: boolean }>`
  width: 168px;
  box-shadow: 0px 3px 6px #00000029;
  text-align: center;
  font-size: 12px;
  padding: 8px;
  color: ${({ selected }) => (selected ? colors.green : colors.white)};
  background-color: ${({ selected }) =>
    selected ? colors.white : colors.darkGreen};
`;

export default RankingPage;
