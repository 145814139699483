import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { onError } from "apollo-link-error";
import { createHttpLink } from "apollo-link-http";

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_API_DOMAIN}/graphql`,
  credentials: "same-origin"
});

const authLink = setContext(async (_, { headers }) => {
  const token = localStorage.getItem("token");

  if (!token) return;

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`
    }
  };
});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message }) => {
          console.log(message);
        });
      }
      if (networkError) {
        console.log(networkError);
      }
    }),
    authLink.concat(httpLink)
  ]),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
      errorPolicy: "all"
    },
    query: {
      fetchPolicy: "network-only",
      errorPolicy: "all"
    },
    mutate: {
      errorPolicy: "all"
    }
  },
  connectToDevTools: !!process.env.REACT_APP_LOCAL
});

export default client;
