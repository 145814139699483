import refreshToken from "api/refreshToken";
import DrivingEvaluation from "components/Organisms/DrivingEvaluation";
import ErrorPage from "components/Organisms/ErrorPage";
import Home from "components/Organisms/Home";
import Info from "components/Organisms/Info";
import Loading from "components/Organisms/Loading";
import Login from "components/Organisms/Login";
import Map from "components/Organisms/Map";
import MyPage from "components/Organisms/MyPage";
import Ranking from "components/Organisms/Ranking";
import React, { useEffect } from "react";
import { isMobile } from "react-device-detect";
import {
  Redirect,
  Route,
  RouteProps,
  BrowserRouter as Router,
  Switch
} from "react-router-dom";
import styled from "styled-components";

const OnlyMobileRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [state, setState] = React.useState<"loading" | "pc" | "mobile">(
    "loading"
  );

  useEffect(() => {
    setState(isMobile ? "mobile" : "pc");
  }, []);

  if (state === "pc") {
    return <Redirect to="/" />;
  }

  return (
    <Route
      {...rest}
      render={() => (state === "loading" ? <Loading /> : children)}
    />
  );
};

const PrivateRoute: React.FC<RouteProps> = ({ children, ...rest }) => {
  const [state, setAuth] = React.useState<
    "loading" | "invalid" | "valid" | "pc"
  >("loading");

  useEffect(() => {
    if (!isMobile) {
      setAuth("pc");
      return;
    }
    const refresh = async () => {
      const res = await refreshToken();
      setAuth(res ? "valid" : "invalid");
    };
    refresh();
  }, [children]);

  if (state === "pc") {
    return <Redirect to="/" />;
  }

  if (state === "invalid") {
    return <Redirect to="/login" />;
  }

  return (
    <Route
      {...rest}
      render={() => {
        return state === "valid" ? children : <Loading />;
      }}
    />
  );
};

const App: React.FC = () => {
  return (
    <Container>
      <Router>
        <Switch>
          <Route strict exact path="/">
            <Home />
          </Route>
          <Route strict exact path="/home">
            <Home />
          </Route>
          <OnlyMobileRoute strict exact path="/login">
            <Login />
          </OnlyMobileRoute>
          <PrivateRoute strict exact path="/map">
            <Map />
          </PrivateRoute>
          <PrivateRoute path="/mypage">
            <MyPage />
          </PrivateRoute>
          <PrivateRoute strict exact path="/ranking">
            <Ranking />
          </PrivateRoute>
          <PrivateRoute strict exact path="/driving-evalution">
            <DrivingEvaluation />
          </PrivateRoute>
          <PrivateRoute strict exact path="/info">
            <Info />
          </PrivateRoute>
          <Route path="*">
            <ErrorPage />
          </Route>
        </Switch>
      </Router>
    </Container>
  );
};

const Container = styled.div`
  max-width: 1200px;
  min-width: 300px;
  width: 100%;
  padding-bottom: 90px;
  margin: auto;
`;

export default App;
