import { useSignInUserMutation } from "api/graphql";
import colors from "colors";
import Button from "components/Atoms/Button";
import Card from "components/Atoms/Card";
import Text from "components/Atoms/Text";
import ErrorDialog from "components/Molecules/ErrorDialog";
import Header from "components/Molecules/Header";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { LoginValidation } from "validation";

type FormData = {
  email: string;
  password: string;
};

const Login = () => {
  const history = useHistory();
  const { register, handleSubmit, errors, setError } = useForm<FormData>();
  const [signInUserMutation] = useSignInUserMutation({
    onError: () => {
      setDialog(true);
    }
  });
  const [isOpenDiglog, setDialog] = useState(false);

  const onSubmit = handleSubmit(async ({ email, password }) => {
    try {
      const res = await signInUserMutation({
        variables: { email, password }
      });
      if (!res.data?.signInUser) {
        throw new Error();
      }
      if (res.data.signInUser.errors) {
        const error = res.data.signInUser.errors[0];
        if (error === "email") {
          // Email存在しない
          setError("email", "email", "メールアドレスが間違っています");
          return;
        }
        if (error === "password") {
          // パスワード間違い
          setError("password", "password", "パスワードが間違っています");
          return;
        }
      }
      if (res.data.signInUser.user?.token) {
        localStorage.setItem("token", res.data.signInUser.user.token);
        history.push("/mypage");
      }
    } catch (err) {
      console.error("Error", err);
    }
  });

  const handleClose = () => {
    setDialog(false);
  };

  return (
    <>
      <ErrorDialog isOpen={isOpenDiglog} onClose={handleClose} />
      <Header color="yellow" isLoginButton={false} />
      <Container>
        <Card>
          <ContentDiv>
            <img
              src={`${process.env.PUBLIC_URL}/images/login/logo.svg`}
              alt="logo"
            />
            <TextDiv>
              <Text size={24} color="green" fontWeight="bold">
                ログイン
              </Text>
            </TextDiv>
            <Text>サービス申し込み時に配布のID票に</Text>
            <Text>記載のID・パスワードを入力してください。</Text>
            <FormArea>
              <InputArea error={!!errors.email}>
                <Icon
                  src={`${process.env.PUBLIC_URL}/images/login/id.svg`}
                  alt="id"
                  error={!!errors.email}
                />
                <Input
                  type="text"
                  name="email"
                  placeholder="メールアドレス"
                  ref={register(LoginValidation.email)}
                />
              </InputArea>
              <HelperTextDiv>
                <Text size="small" color="error">
                  {errors.email?.message || " "}
                </Text>
              </HelperTextDiv>
              <InputArea error={!!errors.password}>
                <Icon
                  src={`${process.env.PUBLIC_URL}/images/login/key.svg`}
                  alt="key"
                  error={!!errors.password}
                />
                <Input
                  type="password"
                  name="password"
                  placeholder="パスワード"
                  ref={register(LoginValidation.password)}
                />
              </InputArea>
              <HelperTextDiv>
                <Text size="small" color="error">
                  {errors.password?.message || " "}
                </Text>
              </HelperTextDiv>
            </FormArea>
            <Button onClick={onSubmit}>ログイン</Button>
          </ContentDiv>
        </Card>
      </Container>
    </>
  );
};

const Container = styled.div`
  padding: 10px 20px 56px 20px;
  background-color: ${colors.lightYellow};
  height: calc(100vh - 70px);
  margin-bottom: -90px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
`;

const TextDiv = styled.div`
  padding-bottom: 20px;
`;

const FormArea = styled.div`
  padding: 20px;
`;

const InputArea = styled.div<{ error: boolean }>`
  border: 1px solid ${({ error }) => (error ? colors.error : colors.green)};
  border-radius: 2px;
  align-items: center;
  display: flex;
`;

const Icon = styled.img<{ error: boolean }>`
  margin: 4px 0;
  padding: 0 4px;
  width: 32px;
  height: 32px;
  border-right: 1px solid
    ${({ error }) => (error ? colors.error : colors.green)};
`;

const Input = styled.input`
  border: none;
  outline: none;
  width: 255px;
  height: 40px;
  margin-left: 5px;
  font-size: 14px;
`;

const HelperTextDiv = styled.div`
  padding: 4px 8px 8px 8px;
  height: 15px;
`;

export default Login;
