import {
  DailyDrivingEvaluation,
  Feeling,
  useDrivingEvaluationQuery
} from "api/graphql";
import colors from "colors";
import Card from "components/Atoms/Card";
import EvaluationText from "components/Atoms/EvaluationText";
import Text from "components/Atoms/Text";
import ErrorDialog from "components/Molecules/ErrorDialog";
import Footer from "components/Molecules/Footer";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

type Evaluation = Pick<
  DailyDrivingEvaluation,
  "droveDate" | "evaluation" | "numBrake" | "numFall"
>;

type User = { name: string; feeling: Feeling };

const DrivingEvaluation = () => {
  const [isOpenDiglog, setDialog] = useState(false);
  const [user, setUser] = useState<User>({
    name: "",
    feeling: Feeling.Calculating
  });
  const [evaluation, setEvaluation] = useState<Evaluation>({
    droveDate: "",
    evaluation: "",
    numBrake: 0,
    numFall: 0
  });
  const { error } = useDrivingEvaluationQuery({
    onCompleted: data => {
      if (data.getUser) {
        setUser(data.getUser);
      }
      if (data.getUser.lastEvaluation) {
        setEvaluation(data.getUser.lastEvaluation);
      }
    }
  });

  useEffect(() => {
    // 通信エラー
    if (error?.networkError) {
      setDialog(true);
    }
  }, [error]);

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const phrase = () => {
    switch (user.feeling) {
      case Feeling.Perfect:
        return "みんなのお手本になる運転です!";
      case Feeling.VeryGood:
        return "上手に運転しています！";
      case Feeling.Good:
        return "その調子で運転しましょう！";
      case Feeling.NotGood:
        return "運転の調子が良くないです。";
      case Feeling.Bad:
        return "荒い運転になっています";
      case Feeling.Dangerous:
        return "危ない運転になっています。";
      case Feeling.Calculating:
        return "";
      default:
        console.error("Unknown Feeling");
    }
  };
  const FeelingContent = () => {
    switch (user.feeling) {
      case Feeling.Perfect:
        return (
          <>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/evaluation/feeling01.svg`}
                alt="feeling1"
              />
              <SpeechBubble>とても安心</SpeechBubble>
            </div>
            <ContentDiv>
              <Text>
                安全運転が維持されています。{user.name}
                さんは素晴らしい運転をしています。
              </Text>
            </ContentDiv>
          </>
        );
      case Feeling.VeryGood:
        return (
          <>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/evaluation/feeling02.svg`}
                alt="feeling2"
              />
              <SpeechBubble>安心</SpeechBubble>
            </div>
            <ContentDiv>
              <Text>
                安全運転の意識が高まっています。 {user.name}
                さんを褒めてあげましょう。
              </Text>
            </ContentDiv>
          </>
        );
      case Feeling.Good:
        return (
          <>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/evaluation/feeling03.svg`}
                alt="feeling3"
              />
              <SpeechBubble>やや安心</SpeechBubble>
            </div>
            <ContentDiv>
              <Text>
                安全運転の意識が高まりつつあります。{user.name}
                さんを褒めてあげましょう。
              </Text>
            </ContentDiv>
          </>
        );
      case Feeling.NotGood:
        return (
          <>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/evaluation/feeling04.svg`}
                alt="feeling4"
              />
              <SpeechBubble>やや不安</SpeechBubble>
            </div>
            <ContentDiv>
              <Text>
                運転に大きな変化は見られませんが、危険運転の傾向にあります。改めて意識を持たせるために、「最近、自転
                車、楽しい？」など、声掛けをしてみましょう。
              </Text>
            </ContentDiv>
          </>
        );
      case Feeling.Bad:
        return (
          <>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/evaluation/feeling05.svg`}
                alt="feeling5"
              />
              <SpeechBubble>不安</SpeechBubble>
            </div>
            <ContentDiv>
              <Text>
                最近、安全運転ではなくなってきています。自転車運転への慣れや生活でのストレスがあるのかもしれません。
                「最近、何かあった？」など声掛けをしてあげてください。
              </Text>
            </ContentDiv>
          </>
        );
      case Feeling.Dangerous:
        return (
          <>
            <div>
              <img
                src={`${process.env.PUBLIC_URL}/images/evaluation/feeling06.svg`}
                alt="feeling6"
              />
              <SpeechBubble>とても不安</SpeechBubble>
            </div>
            <ContentDiv>
              <Text>
                危険運転の回数が増えています。自転車運転への慣れや生活でのストレスがあるのかもしれません。
                「最近、何かあった？」など声掛けをしてあげてください。
              </Text>
            </ContentDiv>
          </>
        );
      case Feeling.Calculating:
        return (
          <TextDiv>
            <Text color="textSecondary">計算中</Text>
          </TextDiv>
        );
      default:
        console.error("Unknown Feeling");
        return <></>;
    }
  };

  if (error?.graphQLErrors) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ErrorDialog isOpen={isOpenDiglog} onClose={handleCloseDialog} />
      <BackImageDiv>
        <Image
          src={`${process.env.PUBLIC_URL}/images/evaluation/back_top.jpg`}
          alt="backimage"
        />
        <TitleDiv>
          <Title>{phrase()}</Title>
        </TitleDiv>
      </BackImageDiv>
      <CardDiv>
        <Card>
          <CardTitle>
            <Title>運転評価</Title>
            <div>
              <Text size="small" color="textSecondary">
                {evaluation.droveDate && `計測日 ${evaluation.droveDate}`}
              </Text>
            </div>
          </CardTitle>
          <WidthBorder />
          <CardContent>
            <div>
              <Text>評価</Text>
              {evaluation.evaluation ? (
                <EvaluationText color="green" size="large">
                  {evaluation.evaluation}
                </EvaluationText>
              ) : (
                <EvaluationTextDiv>
                  <Text color="textSecondary">計算中</Text>
                </EvaluationTextDiv>
              )}
            </div>
            <LengthBorder />
            <div>
              <Text>急ブレーキ</Text>
              <EvaluationText color="green" size="large">
                {evaluation.numBrake}
              </EvaluationText>
            </div>
            <LengthBorder />
            <div>
              <Text>転倒回数</Text>
              <EvaluationText color="green" size="large">
                {evaluation.numFall}
              </EvaluationText>
            </div>
          </CardContent>
        </Card>
      </CardDiv>
      <CardDiv>
        <Card title="運転の傾向">
          <CardContent>
            <FeelingContent />
          </CardContent>
        </Card>
      </CardDiv>
      <Footer />
    </>
  );
};

const BackImageDiv = styled.div`
  position: relative;
`;

const Image = styled.img`
  width: 100%;
  min-height: 230px;
`;

const TitleDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
`;

const CardDiv = styled.div`
  padding: 10px 20px;
`;

const CardTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-bottom: 5px;
`;

const WidthBorder = styled.div`
  border-bottom: 1px solid ${colors.border};
`;

const LengthBorder = styled.div`
  border-right: 1px solid ${colors.border};
`;

const Title = styled.div`
  color: ${colors.text};
  font-size: 16px;
  text-align: center;
  font-weight: 600;
`;

const CardContent = styled.div`
  display: flex;
  justify-content: space-around;
  padding-top: 10px;
`;

const SpeechBubble = styled.div`
  background-color: ${colors.green};
  color: ${colors.white};
  text-align: center;
  font-size: 10px;
  border-radius: 10px;
  width: 60px;
`;

const ContentDiv = styled.div`
  padding-left: 20px;
`;

const TextDiv = styled.div`
  padding-bottom: 15px;
`;

const EvaluationTextDiv = styled.div`
  padding-top: 20px;
`;

export default DrivingEvaluation;
