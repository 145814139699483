import {
  Grade,
  MypageQuery,
  useMypageQuery,
  useSignOutUserMutation
} from "api/graphql";
import client from "client";
import colors from "colors";
import EvaluationText from "components/Atoms/EvaluationText";
import Text from "components/Atoms/Text";
import UserIcon from "components/Atoms/UserIcon";
import ErrorDialog from "components/Molecules/ErrorDialog";
import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

const INIT = {
  name: "",
  grade: Grade.Member,
  lastEvaluation: null,
  todayRanking: null
};

const MyPage = () => {
  const history = useHistory();
  const [isOpenDiglog, setDialog] = useState(false);
  const [data, setData] = useState<MypageQuery>({ getUser: INIT });
  const { error } = useMypageQuery({
    onCompleted: data => {
      if (data) {
        setData(data);
      }
    }
  });
  const [signOutUser] = useSignOutUserMutation();

  useEffect(() => {
    // 通信エラー
    if (error?.networkError) {
      setDialog(true);
    }
  }, [error]);

  const handleClick = (path: string) => () => {
    history.push(path);
  };

  const handleCloseDialog = () => {
    setDialog(false);
  };

  const handleLogout = async () => {
    const res = await signOutUser();
    if (!res.data) {
      throw new Error("No Response");
    }
    if (res.data.signOutUser) {
      if (res.data.signOutUser.errors.length !== 0) {
        console.error("Error", res.data.signOutUser.errors);
      }
      localStorage.removeItem("token");
      await client.resetStore();
      history.push("/login");
    }
  };

  const GradeIcon: React.FC<{ grade: Grade }> = ({ grade }) => {
    const text = () => {
      switch (grade) {
        case "member":
          return "メンバー";
        case "bronze":
          return "ブロンズ";
        case "silver":
          return "シルバー";
        case "gold":
          return "ゴールド";
        case "platinum":
          return "プラチナ";
        default:
          return "";
      }
    };
    return (
      <GradeIconDiv name={grade}>
        <GradeImg
          src={`${process.env.PUBLIC_URL}/images/mypage/grade_${grade}.svg`}
          alt="gold"
          width="15px"
          height="13px"
        />
        <GradeText name={grade}>{text()}</GradeText>
      </GradeIconDiv>
    );
  };

  // 認証エラーが出たらログイン画面へリダイレクト
  if (error?.graphQLErrors) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ErrorDialog isOpen={isOpenDiglog} onClose={handleCloseDialog} />
      <UserArea name={data.getUser.grade}>
        <IconDiv onClick={handleLogout}>
          <img
            src={`${process.env.PUBLIC_URL}/images/mypage/logout.svg`}
            alt="logout"
          />
        </IconDiv>
        <UserIcon size="large" />
        <TextDiv>
          <Text size="large" fontWeight="bold">
            {data.getUser.name}
          </Text>
        </TextDiv>
        <GradeIcon grade={data.getUser.grade} />
      </UserArea>
      <ButtonsArea>
        <ButtonArea
          name="evaluation"
          onClick={handleClick("/driving-evalution")}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/mypage/assessment.svg`}
            alt="assessment"
          />
          <CenterText>
            <EvaluationText color="white" size="large">
              {data.getUser.lastEvaluation?.evaluation || "－"}
            </EvaluationText>
          </CenterText>
          <Button>運転評価</Button>
        </ButtonArea>
        <ButtonArea name="ranking" onClick={handleClick("/ranking")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/mypage/ranking.svg`}
            alt="ranking"
          />
          <CenterText>
            <EvaluationText color="white" size="large">
              {data.getUser.todayRanking ? (
                <>
                  {data.getUser.todayRanking.place}
                  <UnitText>位</UnitText>
                </>
              ) : (
                "－"
              )}
            </EvaluationText>
          </CenterText>
          <Button>ランキング</Button>
        </ButtonArea>
        <ButtonArea name="info" onClick={handleClick("/info")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/mypage/info.svg`}
            alt="info"
          />
          <Button>お知らせ</Button>
        </ButtonArea>
        <ButtonArea name="map" onClick={handleClick("/map")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/mypage/map.svg`}
            alt="map"
          />
          <Button>ハザードマップ</Button>
        </ButtonArea>
      </ButtonsArea>
    </>
  );
};

const UserArea = styled.div<{ name: Grade }>`
  background-image: ${({ name }) =>
    `url("${process.env.PUBLIC_URL}/images/mypage/back_${name}.jpg")`};
  background-size: cover;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 44px 25px;
  margin-bottom: 10px;
`;

const IconDiv = styled.div`
  align-self: flex-end;
  border: 1px solid ${colors.green};
  height: 40px;
  width: 40px;
  border-radius: 40px;
`;

const GradeImg = styled.img`
  vertical-align: middle;
`;

const GradeIconDiv = styled.div<{ name: Grade }>`
  border: 1px solid ${({ name }) => colors[name]};
  border-radius: 15px;
  padding: 2px 5px;
  height: 20px;
  line-height: 18px;
`;

const GradeText = styled.div<{ name: Grade }>`
  font-size: 10px;
  padding-left: 2px;
  display: inline-block;
  color: ${({ name }) => colors[name]};
`;

const ButtonArea = styled.div<{ name: string }>`
  height: ${({ name }) => {
    if (name === "evaluation" || name === "ranking") {
      return "170px";
    } else {
      return "120px";
    }
  }};
  background-image: ${({ name }) => {
    if (name === "evaluation" || name === "map") {
      return `url("${process.env.PUBLIC_URL}/images/mypage/back_button_green.jpg")`;
    } else {
      return `url("${process.env.PUBLIC_URL}/images/mypage/back_button.jpg")`;
    }
  }};
  background-size: cover;
  width: 150px;
  border-radius: 10px;
  margin: 10px;
  box-shadow: 0px 8px 15px ${colors.greenShadow};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
`;

const Button = styled.button`
  width: 130px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colors.white};
  color: ${colors.green};
  border-radius: 20px;
  box-shadow: 0px 8px 15px ${colors.greenShadow};
  margin: 10px;
  align-self: center;
  font-weight: 600;
  height: 30px;
`;

const CenterText = styled.div`
  align-self: center;
`;

const TextDiv = styled.div`
  padding: 5px;
`;

const UnitText = styled.span`
  font-size: 12px;
`;

const ButtonsArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export default MyPage;
