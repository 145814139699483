import colors from "colors";
import Text from "components/Atoms/Text";
import React from "react";
import styled from "styled-components";

const FooterComponent = () => {
  return (
    <Footer>
      <ImgDiv>
        <img
          src={`${process.env.PUBLIC_URL}/images/header/cycle_sign.svg`}
          alt="cycle_sign"
          width={170}
          height={25}
        />
      </ImgDiv>
      <TextDiv>
        <Text size="small" color="white">
          ©︎さいくるサイン
        </Text>
      </TextDiv>
    </Footer>
  );
};

const Footer = styled.footer`
  height: 110px;
  background-color: ${colors.green};
`;

const ImgDiv = styled.div`
  background-color: ${colors.white};
  text-align: center;
  padding: 24px;
`;

const TextDiv = styled.div`
  text-align: center;
  padding: 10px;
`;

export default FooterComponent;
