import colors from "colors";
import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

type Props = {
  color?: "transparent" | "yellow";
  isLoginButton?: boolean;
};

const Header: React.FC<Props> = ({
  color = "transparent",
  isLoginButton = true
}) => {
  return (
    <Container color={color} isLoginButton={isLoginButton}>
      <ImgLink to="/home">
        <img
          src={`${process.env.PUBLIC_URL}/images/header/cycle_sign.svg`}
          alt="cycle_sign"
          width={170}
          height={25}
        />
      </ImgLink>
      {isLoginButton && (
        <>
          <LoginIconLink to="/login">
            <img
              src={`${process.env.PUBLIC_URL}/images/header/mypage.svg`}
              alt="login"
            />
          </LoginIconLink>
          <Button to="/login">ログイン</Button>
        </>
      )}
    </Container>
  );
};

const Container = styled.div<Required<Props>>`
  height: 70px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  background-color: ${({ color }) =>
    color === "transparent" ? "transparent" : colors.lightYellow};
`;

const ImgLink = styled(Link)`
  flex-grow: 2;
`;

// スマホ画面(426px以下)のときアイコン表示
const LoginIconLink = styled(Link)`
  border-radius: 2px;
  background-color: ${colors.green};
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
  @media (min-width: 426px) {
    display: none;
  }
`;

// PC画面（426px以上)のときボタン表示
const Button = styled(Link)`
  min-width: 120px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colors.green};
  color: ${colors.white};
  border-radius: 25px;
  font-size: 14px;
  text-align: center;
  text-decoration: none;
  padding: 8px;
  @media (max-width: 425px) {
    display: none;
  }
`;

export default Header;
