import { Alert, InfoQuery, useInfoQuery } from "api/graphql";
import colors from "colors";
import Card from "components/Atoms/Card";
import Text from "components/Atoms/Text";
import ErrorDialog from "components/Molecules/ErrorDialog";
import Footer from "components/Molecules/Footer";
import { InformationLink } from "const";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

const Info = () => {
  const [isOpenDiglog, setDialog] = useState(false);
  const [data, setData] = useState<InfoQuery>({ getAlertHistoryies: [] });
  const { error } = useInfoQuery({
    onCompleted: data => {
      if (data) {
        setData(data);
      }
    }
  });

  useEffect(() => {
    // 通信エラー
    if (error?.networkError) {
      setDialog(true);
    }
  }, [error]);

  const handleCloseDialog = () => {
    setDialog(false);
  };

  if (error?.graphQLErrors) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ErrorDialog isOpen={isOpenDiglog} onClose={handleCloseDialog} />
      <BulletinBoard>
        <Text fontWeight="bold" size={16}>
          掲示板
        </Text>
        <ContentDiv>
          <Link href={InformationLink.campaign}>
            <Text fontWeight="bold" size={16}>
              キャンペーン関連
            </Text>
            <Icon />
          </Link>
        </ContentDiv>
        <ContentDiv>
          <Link href={InformationLink.event}>
            <Text fontWeight="bold" size={16}>
              イベント関連
            </Text>
            <Icon />
          </Link>
        </ContentDiv>
        <ContentDiv>
          <Link href={InformationLink.trend}>
            <Text fontWeight="bold" size={16}>
              トレンド関連
            </Text>
            <Icon />
          </Link>
        </ContentDiv>
        <ContentDiv>
          <Link href={InformationLink.municipalities}>
            <Text fontWeight="bold" size={16}>
              自治体関連
            </Text>
            <Icon />
          </Link>
        </ContentDiv>
      </BulletinBoard>
      <AlertHistoryDiv>
        <Text fontWeight="bold" size={16}>
          アラート履歴
        </Text>
        <ContentDiv>
          <Card>
            {!data.getAlertHistoryies ||
            data.getAlertHistoryies.length === 0 ? (
              <Text color="textSecondary">履歴はありません</Text>
            ) : (
              data.getAlertHistoryies.map((v, i) => {
                const type = (() => {
                  switch (v.alertType) {
                    case Alert.Report:
                      return "急ブレーキアラート";
                    case Alert.Braking:
                      return "急ブレーキアラート";
                    case Alert.Turning:
                      return "転倒アラート";
                    default:
                      console.error("alert type is wrong");
                  }
                })();
                const text = (() => {
                  switch (v.alertType) {
                    case Alert.Report:
                      return "過去5日間で急ブレーキが多く見られます";
                    case Alert.Braking:
                      return "急ブレーキが急増しています";
                    case Alert.Turning:
                      return "転倒を検知しました";
                    default:
                      console.error("alert type is wrong");
                  }
                })();
                return (
                  <div key={i}>
                    <TextDiv>
                      <Text size="small" color="green" fontWeight="bold">
                        {v.recievedDate} {type}
                      </Text>
                    </TextDiv>
                    <TextDiv>
                      <Text>{text}</Text>
                    </TextDiv>
                    <WidthBorder />
                  </div>
                );
              })
            )}
          </Card>
        </ContentDiv>
      </AlertHistoryDiv>
      <Footer />
    </>
  );
};

const BulletinBoard = styled.div`
  padding: 44px 20px 20px 20px;
  background-color: ${colors.lightPink};
`;

const ContentDiv = styled.div`
  padding: 5px 0;
`;

const Link = styled.a`
  text-decoration: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 5px;
  background-color: #ffffff;
  box-shadow: 0px 0px 5px #3333331a;
  border-radius: 10px;
  padding: 15px;
`;

const TextDiv = styled.div`
  padding: 5px;
`;

const Icon = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid;
  border-color: ${colors.text} ${colors.text} transparent transparent;
  transform: rotate(45deg);
`;

const WidthBorder = styled.div`
  border-bottom: 1px solid ${colors.border};
  width: 100%;
  margin: 5px 0;
`;

const AlertHistoryDiv = styled.div`
  padding: 20px;
`;

export default Info;
