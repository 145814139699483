import colors from "colors";
import Header from "components/Molecules/Header";
import React from "react";
import styled, { keyframes } from "styled-components";

const Loading = () => {
  return (
    <>
      <Header />
      <LoaderDiv>
        <Loader />
      </LoaderDiv>
    </>
  );
};

const LoaderDiv = styled.div`
  width: 100%;
`;

const load = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loader = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin: 10vh auto;
  border-top: 8px solid ${colors.green};
  border-right: 8px solid ${colors.green};
  border-bottom: 8px solid ${colors.green};
  border-left: 8px solid ${colors.background};
  animation: ${load} 1.1s infinite linear;
`;

export default Loading;
