import colors from "colors";
import React from "react";
import styled from "styled-components";

type Props = {
  size?: "small" | "large";
  color?: "default" | "pink" | "green" | "blue";
};

const DefaultIcon: React.FC<Props> = ({
  size = "small",
  color = "default"
}) => {
  const url = `${process.env.PUBLIC_URL}/images/icon/default${
    color === "default" ? "" : `_${color}`
  }.png`;
  return (
    <UserIconDiv size={size} color={color}>
      <UserIcon src={url} alt="user" size={size} color={color} />
    </UserIconDiv>
  );
};

const UserIcon = styled.img<Required<Props>>`
  height: ${({ size }) => (size === "small" ? "40px" : "100px")};
  width: ${({ size }) => (size === "small" ? "40px" : "100px")};
  border-radius: ${({ size }) => (size === "small" ? "40px" : "100px")};
  object-fit: contain;
  margin-top: ${({ size }) => (size === "small" ? "10px" : "20px")};
`;

const UserIconDiv = styled.div<Required<Props>>`
  width: ${({ size }) => (size === "small" ? "50px" : "120px")};
  height: ${({ size }) => (size === "small" ? "50px" : "120px")};
  border-radius: ${({ size }) => (size === "small" ? "50px" : "120px")};
  background-color: ${colors.background};
  text-align: center;
`;

export default DefaultIcon;
