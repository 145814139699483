export default {
  background: "#F8F8F8",
  white: "#ffffff",
  green: "#00AC84",
  darkGreen: "#009573",
  lightGreen: "#EBFF00",
  text: "#333333",
  textSecondary: "#707070",
  border: "#d3d3d3",
  pink: "#FFA096",
  lightPink: "#FFBFB9",
  greenShadow: "#2FAA394D",
  member: "#00AC84",
  bronze: "#744300",
  silver: "#707070",
  gold: "#959500",
  platinum: "#67ACA9",
  lightYellow: "#F9FFB9",
  error: "#FF1800"
};
