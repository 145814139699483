import colors from "colors";
import React from "react";
import styled from "styled-components";

type Props = {
  title?: string;
};

const CardComponent: React.FC<Props> = ({ title, children }) => {
  return (
    <Card>
      {title && <Title>{title}</Title>}
      {children}
    </Card>
  );
};

const Card = styled.div`
  background-color: ${colors.white};
  box-shadow: 0px 0px 5px #3333331a;
  border-radius: 10px;
  padding: 15px;
`;

const Title = styled.div`
  color: ${colors.text};
  font-size: 16px;
  font-weight: 600;
`;

export default CardComponent;
