import colors from "colors";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

const FooterComponent = () => {
  const history = useHistory();
  const handleClick = (url: string) => () => {
    history.push(url);
  };
  return (
    <Footer>
      <FooterDiv>
        <NavButton onClick={handleClick("/driving-evalution")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/footer/assessment.svg`}
            alt="Assessment"
          />
          <LinkText>運転評価</LinkText>
        </NavButton>
        <NavButton onClick={handleClick("ranking")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/footer/ranking.svg`}
            alt="ranking"
          />
          <LinkText>
            <LinkText>ランキング</LinkText>
          </LinkText>
        </NavButton>
        <HomeNavButton onClick={handleClick("mypage")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/footer/mypage.svg`}
            alt="Mypage"
          />
          <HomeLinkText>マイページ</HomeLinkText>
        </HomeNavButton>
        <NavButton onClick={handleClick("info")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/footer/info.svg`}
            alt="info"
          />
          <LinkText>お知らせ</LinkText>
        </NavButton>
        <NavButton onClick={handleClick("map")}>
          <img
            src={`${process.env.PUBLIC_URL}/images/footer/map.svg`}
            alt="map"
          />
          <LinkText>マップ</LinkText>
        </NavButton>
      </FooterDiv>
    </Footer>
  );
};

const Footer = styled.header`
  width: 100%;
  max-width: 1200px;
  height: 90px;
  bottom: 0;
  position: fixed;
  background-color: ${colors.white};
`;

const FooterDiv = styled.div`
  padding: 0 20px;
  display: flex;
  justify-content: space-around;
  align-items: baseline;
`;

const NavButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 4px 0px;
`;

const HomeNavButton = styled.button`
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 4px 0px;
  top: -10px;
`;

const LinkText = styled.div`
  width: 50px;
  height: 10px;
  text-align: center;
  font-size: 10px;
  color: ${colors.textSecondary};
`;

const HomeLinkText = styled.div`
  width: 55px;
  height: 11px;
  text-align: center;
  color: ${colors.textSecondary};
  font-size: 11px;
`;

export default FooterComponent;
