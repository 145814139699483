import gql from "graphql-tag";
import * as ApolloReactCommon from "@apollo/react-common";
import * as ApolloReactHooks from "@apollo/react-hooks";
export type Maybe<T> = T | null;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Datetime: string;
};

export enum Alert {
  Report = "report",
  Braking = "braking",
  Turning = "turning"
}

export type AlertHistory = {
  readonly alertType: Alert;
  readonly id: Scalars["ID"];
  readonly recievedDate: Scalars["Datetime"];
  readonly user: User;
};

export type DailyDrivingEvaluation = {
  readonly deviceNumber: Scalars["String"];
  readonly drivingTime: Scalars["Int"];
  readonly droveDate: Scalars["Datetime"];
  readonly evaluation: Scalars["String"];
  readonly isDriven: Scalars["Boolean"];
  readonly numBrake: Scalars["Int"];
  readonly numFall: Scalars["Int"];
  readonly point: Scalars["Int"];
  readonly score: Scalars["Float"];
  readonly user: User;
};

export enum Feeling {
  Perfect = "perfect",
  VeryGood = "very_good",
  Good = "good",
  NotGood = "not_good",
  Bad = "bad",
  Dangerous = "dangerous",
  Calculating = "calculating"
}

export type FirstRanking = {
  readonly dailyDrivingEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly place: Scalars["Int"];
  readonly totalPoint: Scalars["Int"];
  readonly user: User;
};

export type GetAlertHistory = {
  readonly alertType: Alert;
  readonly id: Scalars["ID"];
  readonly recievedDate: Scalars["Datetime"];
  readonly user: User;
};

export type GetHazardMaps = {
  readonly isNearMiss: Scalars["Boolean"];
  readonly lat: Scalars["Float"];
  readonly lng: Scalars["Float"];
};

export type GetMonthlyTransitionRankings = {
  readonly dailyDrivingEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly place: Scalars["Int"];
  readonly totalPoint: Scalars["Int"];
  readonly user: User;
};

export type GetUser = {
  readonly alertHistories?: Maybe<ReadonlyArray<AlertHistory>>;
  readonly drivingEvaluations?: Maybe<ReadonlyArray<DailyDrivingEvaluation>>;
  readonly email: Scalars["String"];
  readonly feeling: Feeling;
  readonly grade: Grade;
  readonly id: Scalars["ID"];
  readonly lastEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly name: Scalars["String"];
  readonly rankings?: Maybe<ReadonlyArray<MonthlyRanking>>;
  readonly todayRanking?: Maybe<MonthlyRanking>;
  readonly token: Scalars["String"];
};

export enum Grade {
  Member = "member",
  Bronze = "bronze",
  Silver = "silver",
  Gold = "gold",
  Platinum = "platinum"
}

export type MonthlyRanking = {
  readonly dailyDrivingEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly place: Scalars["Int"];
  readonly totalPoint: Scalars["Int"];
  readonly user: User;
};

export type Mutation = {
  readonly refreshToken?: Maybe<RefreshTokenPayload>;
  readonly signInUser?: Maybe<SignInUserPayload>;
  readonly signOutUser?: Maybe<SignOutUserPayload>;
};

export type MutationRefreshTokenArgs = {
  input: RefreshTokenInput;
};

export type MutationSignInUserArgs = {
  input: SignInUserInput;
};

export type MutationSignOutUserArgs = {
  input: SignOutUserInput;
};

export type Query = {
  readonly firstRanking: ReadonlyArray<FirstRanking>;
  readonly getAlertHistoryies?: Maybe<ReadonlyArray<GetAlertHistory>>;
  readonly getHazardMaps?: Maybe<ReadonlyArray<GetHazardMaps>>;
  readonly getMonthlyTransitionRankings?: Maybe<
    ReadonlyArray<GetMonthlyTransitionRankings>
  >;
  readonly getUser: GetUser;
  readonly secondRanking: ReadonlyArray<SecondRanking>;
  readonly thirdRanking: ReadonlyArray<ThirdRanking>;
};

export type RefreshTokenInput = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
};

export type RefreshTokenPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly token?: Maybe<Scalars["String"]>;
};

export type SecondRanking = {
  readonly dailyDrivingEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly place: Scalars["Int"];
  readonly totalPoint: Scalars["Int"];
  readonly user: User;
};

export type SignInUserInput = {
  readonly email: Scalars["String"];
  readonly password: Scalars["String"];
  readonly clientMutationId?: Maybe<Scalars["String"]>;
};

export type SignInUserPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly errors: ReadonlyArray<Scalars["String"]>;
  readonly user?: Maybe<User>;
};

export type SignOutUserInput = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
};

export type SignOutUserPayload = {
  readonly clientMutationId?: Maybe<Scalars["String"]>;
  readonly errors: ReadonlyArray<Scalars["String"]>;
};

export type ThirdRanking = {
  readonly dailyDrivingEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly place: Scalars["Int"];
  readonly totalPoint: Scalars["Int"];
  readonly user: User;
};

export type User = {
  readonly alertHistories?: Maybe<ReadonlyArray<AlertHistory>>;
  readonly drivingEvaluations?: Maybe<ReadonlyArray<DailyDrivingEvaluation>>;
  readonly email: Scalars["String"];
  readonly feeling: Feeling;
  readonly grade: Grade;
  readonly id: Scalars["ID"];
  readonly lastEvaluation?: Maybe<DailyDrivingEvaluation>;
  readonly name: Scalars["String"];
  readonly rankings?: Maybe<ReadonlyArray<MonthlyRanking>>;
  readonly todayRanking?: Maybe<MonthlyRanking>;
  readonly token: Scalars["String"];
};

export type RefreshTokenMutationVariables = {};

export type RefreshTokenMutation = {
  readonly refreshToken: Maybe<Pick<RefreshTokenPayload, "token">>;
};

export type SignInUserMutationVariables = {
  email: Scalars["String"];
  password: Scalars["String"];
};

export type SignInUserMutation = {
  readonly signInUser: Maybe<
    Pick<SignInUserPayload, "errors"> & {
      readonly user: Maybe<Pick<User, "token">>;
    }
  >;
};

export type SignOutUserMutationVariables = {};

export type SignOutUserMutation = {
  readonly signOutUser: Maybe<Pick<SignOutUserPayload, "errors">>;
};

export type DrivingEvaluationQueryVariables = {};

export type DrivingEvaluationQuery = {
  readonly getUser: Pick<GetUser, "name" | "feeling"> & {
    readonly lastEvaluation: Maybe<
      Pick<
        DailyDrivingEvaluation,
        "droveDate" | "evaluation" | "numBrake" | "numFall"
      >
    >;
  };
};

export type InfoQueryVariables = {};

export type InfoQuery = {
  readonly getAlertHistoryies: Maybe<
    ReadonlyArray<Pick<GetAlertHistory, "alertType" | "recievedDate">>
  >;
};

export type MapQueryVariables = {};

export type MapQuery = {
  readonly getHazardMaps: Maybe<
    ReadonlyArray<Pick<GetHazardMaps, "isNearMiss" | "lat" | "lng">>
  >;
};

export type RankingQueryVariables = {};

export type RankingQuery = {
  readonly getUser: Pick<GetUser, "name"> & {
    readonly todayRanking: Maybe<Pick<MonthlyRanking, "place" | "totalPoint">>;
  };
  readonly firstRanking: ReadonlyArray<
    Pick<FirstRanking, "totalPoint"> & { readonly user: Pick<User, "name"> }
  >;
  readonly secondRanking: ReadonlyArray<
    Pick<SecondRanking, "totalPoint"> & { readonly user: Pick<User, "name"> }
  >;
  readonly thirdRanking: ReadonlyArray<
    Pick<ThirdRanking, "totalPoint"> & { readonly user: Pick<User, "name"> }
  >;
  readonly getMonthlyTransitionRankings: Maybe<
    ReadonlyArray<
      Pick<GetMonthlyTransitionRankings, "place"> & {
        readonly dailyDrivingEvaluation: Maybe<
          Pick<DailyDrivingEvaluation, "droveDate" | "numFall" | "numBrake">
        >;
      }
    >
  >;
};

export type MypageQueryVariables = {};

export type MypageQuery = {
  readonly getUser: Pick<GetUser, "name" | "grade"> & {
    readonly lastEvaluation: Maybe<Pick<DailyDrivingEvaluation, "evaluation">>;
    readonly todayRanking: Maybe<Pick<MonthlyRanking, "place">>;
  };
};

export const RefreshTokenDocument = gql`
  mutation refreshToken {
    refreshToken(input: {}) {
      token
    }
  }
`;
export type RefreshTokenMutationFn = ApolloReactCommon.MutationFunction<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;

/**
 * __useRefreshTokenMutation__
 *
 * To run a mutation, you first call `useRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshTokenMutation, { data, loading, error }] = useRefreshTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useRefreshTokenMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
  >(RefreshTokenDocument, baseOptions);
}
export type RefreshTokenMutationHookResult = ReturnType<
  typeof useRefreshTokenMutation
>;
export type RefreshTokenMutationResult = ApolloReactCommon.MutationResult<
  RefreshTokenMutation
>;
export type RefreshTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<
  RefreshTokenMutation,
  RefreshTokenMutationVariables
>;
export const SignInUserDocument = gql`
  mutation signInUser($email: String!, $password: String!) {
    signInUser(input: { email: $email, password: $password }) {
      user {
        token
      }
      errors
    }
  }
`;
export type SignInUserMutationFn = ApolloReactCommon.MutationFunction<
  SignInUserMutation,
  SignInUserMutationVariables
>;

/**
 * __useSignInUserMutation__
 *
 * To run a mutation, you first call `useSignInUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInUserMutation, { data, loading, error }] = useSignInUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignInUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignInUserMutation,
    SignInUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SignInUserMutation,
    SignInUserMutationVariables
  >(SignInUserDocument, baseOptions);
}
export type SignInUserMutationHookResult = ReturnType<
  typeof useSignInUserMutation
>;
export type SignInUserMutationResult = ApolloReactCommon.MutationResult<
  SignInUserMutation
>;
export type SignInUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignInUserMutation,
  SignInUserMutationVariables
>;
export const SignOutUserDocument = gql`
  mutation signOutUser {
    signOutUser(input: {}) {
      errors
    }
  }
`;
export type SignOutUserMutationFn = ApolloReactCommon.MutationFunction<
  SignOutUserMutation,
  SignOutUserMutationVariables
>;

/**
 * __useSignOutUserMutation__
 *
 * To run a mutation, you first call `useSignOutUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignOutUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signOutUserMutation, { data, loading, error }] = useSignOutUserMutation({
 *   variables: {
 *   },
 * });
 */
export function useSignOutUserMutation(
  baseOptions?: ApolloReactHooks.MutationHookOptions<
    SignOutUserMutation,
    SignOutUserMutationVariables
  >
) {
  return ApolloReactHooks.useMutation<
    SignOutUserMutation,
    SignOutUserMutationVariables
  >(SignOutUserDocument, baseOptions);
}
export type SignOutUserMutationHookResult = ReturnType<
  typeof useSignOutUserMutation
>;
export type SignOutUserMutationResult = ApolloReactCommon.MutationResult<
  SignOutUserMutation
>;
export type SignOutUserMutationOptions = ApolloReactCommon.BaseMutationOptions<
  SignOutUserMutation,
  SignOutUserMutationVariables
>;
export const DrivingEvaluationDocument = gql`
  query DrivingEvaluation {
    getUser {
      name
      feeling
      lastEvaluation {
        droveDate
        evaluation
        numBrake
        numFall
      }
    }
  }
`;

/**
 * __useDrivingEvaluationQuery__
 *
 * To run a query within a React component, call `useDrivingEvaluationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDrivingEvaluationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDrivingEvaluationQuery({
 *   variables: {
 *   },
 * });
 */
export function useDrivingEvaluationQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    DrivingEvaluationQuery,
    DrivingEvaluationQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<
    DrivingEvaluationQuery,
    DrivingEvaluationQueryVariables
  >(DrivingEvaluationDocument, baseOptions);
}
export function useDrivingEvaluationLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    DrivingEvaluationQuery,
    DrivingEvaluationQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<
    DrivingEvaluationQuery,
    DrivingEvaluationQueryVariables
  >(DrivingEvaluationDocument, baseOptions);
}
export type DrivingEvaluationQueryHookResult = ReturnType<
  typeof useDrivingEvaluationQuery
>;
export type DrivingEvaluationLazyQueryHookResult = ReturnType<
  typeof useDrivingEvaluationLazyQuery
>;
export type DrivingEvaluationQueryResult = ApolloReactCommon.QueryResult<
  DrivingEvaluationQuery,
  DrivingEvaluationQueryVariables
>;
export const InfoDocument = gql`
  query Info {
    getAlertHistoryies {
      alertType
      recievedDate
    }
  }
`;

/**
 * __useInfoQuery__
 *
 * To run a query within a React component, call `useInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useInfoQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<InfoQuery, InfoQueryVariables>
) {
  return ApolloReactHooks.useQuery<InfoQuery, InfoQueryVariables>(
    InfoDocument,
    baseOptions
  );
}
export function useInfoLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    InfoQuery,
    InfoQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<InfoQuery, InfoQueryVariables>(
    InfoDocument,
    baseOptions
  );
}
export type InfoQueryHookResult = ReturnType<typeof useInfoQuery>;
export type InfoLazyQueryHookResult = ReturnType<typeof useInfoLazyQuery>;
export type InfoQueryResult = ApolloReactCommon.QueryResult<
  InfoQuery,
  InfoQueryVariables
>;
export const MapDocument = gql`
  query Map {
    getHazardMaps {
      isNearMiss
      lat
      lng
    }
  }
`;

/**
 * __useMapQuery__
 *
 * To run a query within a React component, call `useMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMapQuery({
 *   variables: {
 *   },
 * });
 */
export function useMapQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<MapQuery, MapQueryVariables>
) {
  return ApolloReactHooks.useQuery<MapQuery, MapQueryVariables>(
    MapDocument,
    baseOptions
  );
}
export function useMapLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MapQuery,
    MapQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MapQuery, MapQueryVariables>(
    MapDocument,
    baseOptions
  );
}
export type MapQueryHookResult = ReturnType<typeof useMapQuery>;
export type MapLazyQueryHookResult = ReturnType<typeof useMapLazyQuery>;
export type MapQueryResult = ApolloReactCommon.QueryResult<
  MapQuery,
  MapQueryVariables
>;
export const RankingDocument = gql`
  query Ranking {
    getUser {
      name
      todayRanking {
        place
        totalPoint
      }
    }
    firstRanking {
      totalPoint
      user {
        name
      }
    }
    secondRanking {
      totalPoint
      user {
        name
      }
    }
    thirdRanking {
      totalPoint
      user {
        name
      }
    }
    getMonthlyTransitionRankings {
      place
      dailyDrivingEvaluation {
        droveDate
        numFall
        numBrake
      }
    }
  }
`;

/**
 * __useRankingQuery__
 *
 * To run a query within a React component, call `useRankingQuery` and pass it any options that fit your needs.
 * When your component renders, `useRankingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRankingQuery({
 *   variables: {
 *   },
 * });
 */
export function useRankingQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    RankingQuery,
    RankingQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<RankingQuery, RankingQueryVariables>(
    RankingDocument,
    baseOptions
  );
}
export function useRankingLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    RankingQuery,
    RankingQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<RankingQuery, RankingQueryVariables>(
    RankingDocument,
    baseOptions
  );
}
export type RankingQueryHookResult = ReturnType<typeof useRankingQuery>;
export type RankingLazyQueryHookResult = ReturnType<typeof useRankingLazyQuery>;
export type RankingQueryResult = ApolloReactCommon.QueryResult<
  RankingQuery,
  RankingQueryVariables
>;
export const MypageDocument = gql`
  query Mypage {
    getUser {
      name
      grade
      lastEvaluation {
        evaluation
      }
      todayRanking {
        place
      }
    }
  }
`;

/**
 * __useMypageQuery__
 *
 * To run a query within a React component, call `useMypageQuery` and pass it any options that fit your needs.
 * When your component renders, `useMypageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMypageQuery({
 *   variables: {
 *   },
 * });
 */
export function useMypageQuery(
  baseOptions?: ApolloReactHooks.QueryHookOptions<
    MypageQuery,
    MypageQueryVariables
  >
) {
  return ApolloReactHooks.useQuery<MypageQuery, MypageQueryVariables>(
    MypageDocument,
    baseOptions
  );
}
export function useMypageLazyQuery(
  baseOptions?: ApolloReactHooks.LazyQueryHookOptions<
    MypageQuery,
    MypageQueryVariables
  >
) {
  return ApolloReactHooks.useLazyQuery<MypageQuery, MypageQueryVariables>(
    MypageDocument,
    baseOptions
  );
}
export type MypageQueryHookResult = ReturnType<typeof useMypageQuery>;
export type MypageLazyQueryHookResult = ReturnType<typeof useMypageLazyQuery>;
export type MypageQueryResult = ApolloReactCommon.QueryResult<
  MypageQuery,
  MypageQueryVariables
>;
