import colors from "colors";
import Text from "components/Atoms/Text";
import Header from "components/Molecules/Header";
import React from "react";
import styled from "styled-components";
import { isMobile } from "react-device-detect";

const Home = () => {
  return (
    <Container
      sp={`${process.env.PUBLIC_URL}/images/top/background_sp.png`}
      pc={`${process.env.PUBLIC_URL}/images/top/background_pc.png`}
    >
      <HeaderDiv>
        <Header isLoginButton={isMobile} />
      </HeaderDiv>
      <ContentDiv>
        <TitleDiv>
          <Text color="green" size={40} fontWeight="bold" display="inlineBlock">
            保護者に安心、
          </Text>
          <Text color="green" size={40} fontWeight="bold" display="inlineBlock">
            子供に安全。
          </Text>
        </TitleDiv>
        <TextDiv>
          <Text display="inlineBlock">親の心配をよそに</Text>
          <Text display="inlineBlock">お子さまは元気よく自転車に乗ります</Text>
        </TextDiv>
        <TextDiv>
          <Text color="green" fontWeight="bold" display="inlineBlock">
            お子さまの普段の運転の様子を
          </Text>
          <Text color="green" fontWeight="bold" display="inlineBlock">
            知ることができたら…
          </Text>
        </TextDiv>
        <TextDiv>
          <Text color="green" fontWeight="bold" display="inlineBlock">
            お子さま自身が
          </Text>
          <Text color="green" fontWeight="bold" display="inlineBlock">
            安全運転を意識するようになったら…
          </Text>
        </TextDiv>
        <LargeTextDiv>
          <Text size="large" fontWeight="bold" display="inlineBlock">
            これまで出来なかったことが
          </Text>
          <Text size="large" fontWeight="bold" display="inlineBlock">
            「<GreenText>さいくるサイン</GreenText>」では
          </Text>
          <Text size="large" fontWeight="bold" display="inlineBlock">
            出来るようになります。
          </Text>
        </LargeTextDiv>
      </ContentDiv>
    </Container>
  );
};

const Container = styled.div<{ sp: string; pc: string }>`
  position: relative;
  width: 100%;
  margin-bottom: -90px;
  height: 100vh;
  object-fit: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-image: url("${({ sp }) => sp}");
  @media(min-width:426px){
    background-image: url("${({ pc }) => pc}");
  }
`;

const HeaderDiv = styled.div`
  width: 100%;
  position: absolute;
  top: 0;
`;

const ContentDiv = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  text-align: center;
  width: 100%;
`;

const GreenText = styled.span`
  color: ${colors.green};
`;

const TitleDiv = styled.div`
  padding: 40px 10px 40px 20px;
  letter-spacing: 4.32px;
`;

const TextDiv = styled.div`
  padding: 10px 60px;
`;

const LargeTextDiv = styled.div`
  padding: 40px 60px;
`;

export default Home;
