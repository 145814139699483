import { RankingQuery } from "api/graphql";
import colors from "colors";
import Card from "components/Atoms/Card";
import EvaluationText from "components/Atoms/EvaluationText";
import Text from "components/Atoms/Text";
import UserIcon from "components/Atoms/UserIcon";
import React from "react";
import styled from "styled-components";

type Props = Pick<
  RankingQuery,
  "getUser" | "firstRanking" | "secondRanking" | "thirdRanking"
>;

const Ranking: React.FC<Props> = ({
  getUser,
  firstRanking,
  secondRanking,
  thirdRanking
}) => {
  return (
    <>
      <CardDiv>
        <Card>
          <TitleDiv>
            <IconDiv>
              <UserIconDiv>
                <UserIcon />
              </UserIconDiv>
              <Text fontWeight="bold">{getUser.name}</Text>
            </IconDiv>
            <LengthBorder />
            <div>
              <Text size={12}>ランキング</Text>
              <EvaluationText color="pink">
                {getUser.todayRanking ? (
                  <>
                    {getUser.todayRanking?.place}
                    <UnitText>位</UnitText>
                  </>
                ) : (
                  <EvaluationTextDiv>
                    <Text color="textSecondary">計算中</Text>
                  </EvaluationTextDiv>
                )}
              </EvaluationText>
            </div>
            <LengthBorder />
            <div>
              <Text size={12}>ポイント</Text>
              <EvaluationText color="pink">
                {getUser.todayRanking?.totalPoint ?? 0}
                <UnitText>P</UnitText>
              </EvaluationText>
            </div>
          </TitleDiv>
        </Card>
      </CardDiv>
      <CardDiv>
        <Card title="前日までのランキング">
          <TitleDiv>
            <WidthBorder />
            <RankingTitle>
              第１位
              {firstRanking.length !== 0 && ` ${firstRanking[0].totalPoint}P`}
            </RankingTitle>
          </TitleDiv>
          <ContentDiv>
            {firstRanking.map((v, i) => {
              const color = (() => {
                switch (i % 3) {
                  case 0:
                    return "blue";
                  case 1:
                    return "green";
                  case 2:
                    return "pink";
                  default:
                    return "default";
                }
              })();
              return (
                <div key={i}>
                  <UserIconDiv>
                    <UserIcon color={color} />
                  </UserIconDiv>
                  <TextDiv>
                    <Text>{v.user.name}</Text>
                  </TextDiv>
                </div>
              );
            })}
          </ContentDiv>
          <TitleDiv>
            <WidthBorder />
            <RankingTitle>
              第２位
              {secondRanking.length !== 0 && ` ${secondRanking[0].totalPoint}P`}
            </RankingTitle>
          </TitleDiv>
          <ContentDiv>
            {secondRanking.map((v, i) => {
              const color = (() => {
                switch (i % 3) {
                  case 0:
                    return "pink";
                  case 1:
                    return "blue";
                  case 2:
                    return "green";
                  default:
                    return "default";
                }
              })();
              return (
                <div key={i}>
                  <UserIconDiv>
                    <UserIcon color={color} />
                  </UserIconDiv>
                  <TextDiv>
                    <Text>{v.user.name}</Text>
                  </TextDiv>
                </div>
              );
            })}
          </ContentDiv>
          <TitleDiv>
            <WidthBorder />
            <RankingTitle>
              第３位
              {thirdRanking.length !== 0 && ` ${thirdRanking[0].totalPoint}P`}
            </RankingTitle>
          </TitleDiv>
          <ContentDiv>
            {thirdRanking.map((v, i) => {
              const color = (() => {
                switch (i % 3) {
                  case 0:
                    return "green";
                  case 1:
                    return "pink";
                  case 2:
                    return "blue";
                  default:
                    return "default";
                }
              })();
              return (
                <div key={i}>
                  <UserIconDiv>
                    <UserIcon color={color} />
                  </UserIconDiv>
                  <TextDiv>
                    <Text>{v.user.name}</Text>
                  </TextDiv>
                </div>
              );
            })}
          </ContentDiv>
        </Card>
      </CardDiv>
    </>
  );
};

const CardDiv = styled.div`
  padding: 10px 20px;
`;

const TitleDiv = styled.div`
  display: flex;
  justify-content: space-around;
`;

const ContentDiv = styled.div`
  display: flex;
  padding: 15px 0;
  flex-wrap: wrap;
`;

const IconDiv = styled.div`
  display: flex;
  align-items: center;
`;

const UserIconDiv = styled.div`
  padding-right: 10px;
`;

const TextDiv = styled.div`
  width: 50px;
  text-align: center;
`;

const WidthBorder = styled.div`
  border-bottom: 1px solid ${colors.border};
  width: 70%;
`;

const LengthBorder = styled.div`
  border-right: 1px solid ${colors.border};
`;

const RankingTitle = styled.div`
  color: ${colors.green};
  font-size: 16px;
  text-align: center;
  font-weight: 600;
`;

const UnitText = styled.span`
  font-size: 16px;
  font-weight: initial;
`;

const EvaluationTextDiv = styled.div`
  padding-top: 10px;
`;

export default Ranking;
