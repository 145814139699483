import { MapQuery, useMapQuery } from "api/graphql";
import colors from "colors";
import Text from "components/Atoms/Text";
import ErrorDialog from "components/Molecules/ErrorDialog";
import Footer from "components/Molecules/Footer";
import GoogleMapReact, { Coords } from "google-map-react";
import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import styled from "styled-components";

// 地図の中心座標
const DEFAULT_CENTER = {
  lat: 35.607414,
  lng: 140.117792
};

const Map = () => {
  const [isOpenDiglog, setDialog] = useState(false);
  const [data, setData] = useState<MapQuery>({ getHazardMaps: [] });
  const { error } = useMapQuery({
    onCompleted: data => {
      if (data) {
        setData(data);
      }
    }
  });

  useEffect(() => {
    // 通信エラー
    if (error?.networkError) {
      setDialog(true);
    }
  }, [error]);

  const handleCloseDialog = () => {
    setDialog(false);
  };

  // 認証エラーが出たらログイン画面へリダイレクト
  if (error?.graphQLErrors) {
    return <Redirect to="/login" />;
  }

  return (
    <>
      <ErrorDialog isOpen={isOpenDiglog} onClose={handleCloseDialog} />
      <Container>
        <GoogleMapReact
          bootstrapURLKeys={{
            key: process.env.REACT_APP_GOOGLE_MAP_API_KEY || ""
          }}
          defaultCenter={{
            lat: DEFAULT_CENTER.lat,
            lng: DEFAULT_CENTER.lng
          }}
          defaultZoom={15}
        >
          {data.getHazardMaps?.map((v, i) => (
            <Maker lat={v.lat} lng={v.lng} key={i}>
              <Img
                src={
                  v.isNearMiss
                    ? `${process.env.PUBLIC_URL}/images/map/near_miss_point.svg`
                    : `${process.env.PUBLIC_URL}/images/map/accident_location.svg`
                }
                alt={`point${i}`}
              />
            </Maker>
          ))}
        </GoogleMapReact>
        <IconArea>
          <IconDiv>
            <img
              src={`${process.env.PUBLIC_URL}/images/map/accident_location.svg`}
              alt="accident"
            />
          </IconDiv>
          <Text size="small">事故発生地点</Text>
          <IconDiv>
            <img
              src={`${process.env.PUBLIC_URL}/images/map/near_miss_point.svg`}
              alt="near_miss"
            />
          </IconDiv>
          <Text size="small">ヒヤリハット地点</Text>
        </IconArea>
      </Container>
      <Footer />
    </>
  );
};

const Container = styled.div`
  width: 100%;
  height: calc(100vh - 90px);
  position: relative;
`;

const Maker = styled.div<Coords>`
  position: relative;
`;

const Img = styled.img`
  height: 26px;
  width: 26px;
  position: absolute;
  left: -13px;
  top: -26px;
`;

const IconDiv = styled.div`
  padding-left: 10px;
`;

const IconArea = styled.div`
  display: flex;
  height: 40px;
  width: 241px;
  align-items: center;
  background-color: ${colors.white};
  border: 1px solid ${colors.border};
  border-radius: 4px;
  position: absolute;
  bottom: 16px;
  left: 20px;
`;

export default Map;
