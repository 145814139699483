import { RankingQuery } from "api/graphql";
import colors from "colors";
import Card from "components/Atoms/Card";
import Text from "components/Atoms/Text";
import React from "react";
import styled from "styled-components";

type Props = Pick<RankingQuery, "getMonthlyTransitionRankings">;

const Transition: React.FC<Props> = ({ getMonthlyTransitionRankings }) => {
  const removeYearDate = (date: string) => date.slice(5);
  return (
    <CardDiv>
      <Card title="当月のランキング推移">
        <ColumnNameArea>
          <Text size="small">日付</Text>
          <Text size="small">転倒回数</Text>
          <Text size="small">急ブレーキ</Text>
          <Text size="small">ランキング</Text>
        </ColumnNameArea>
        <ContentDiv>
          {getMonthlyTransitionRankings &&
          getMonthlyTransitionRankings.length !== 0 ? (
            getMonthlyTransitionRankings.map(
              (v, i) =>
                v.dailyDrivingEvaluation && (
                  <Line key={i}>
                    <Date>
                      {removeYearDate(
                        v.dailyDrivingEvaluation.droveDate as string
                      )}
                    </Date>
                    <DateLine>
                      <IconText>{v.dailyDrivingEvaluation.numFall}</IconText>
                      <LengthBorder />
                      <IconText>{v.dailyDrivingEvaluation.numBrake}</IconText>
                      <LengthBorder />
                      <IconText>{v.place}</IconText>
                    </DateLine>
                  </Line>
                )
            )
          ) : (
            <TextDiv>
              <Text color="textSecondary">データがありません</Text>
            </TextDiv>
          )}
        </ContentDiv>
      </Card>
    </CardDiv>
  );
};

const CardDiv = styled.div`
  padding: 10px 20px;
`;

const ColumnNameArea = styled.div`
  display: flex;
  justify-content: space-around;
  border-bottom: 1px solid ${colors.border};
  padding: 5px;
`;

const ContentDiv = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 285px);
`;

const Line = styled.div`
  background-color: ${colors.background};
  border-radius: 4px;
  display: flex;
  margin: 5px;
`;

const DateLine = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 10px;
`;

const Date = styled.span`
  background-color: ${colors.lightGreen};
  width: 46px;
  text-align: center;
  font-weight: 500;
  padding: 10px;
`;

const IconText = styled.div`
  color: ${colors.green};
  font-size: 20px;
  text-align: center;
  font-weight: 600;
`;

const LengthBorder = styled.div`
  border-right: 1px solid ${colors.border};
`;

const TextDiv = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 20px;
`;

export default Transition;
