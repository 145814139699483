import { RefreshTokenDocument } from "api/graphql";
import client from "client";
import { KJUR } from "jsrsasign";

const refreshToken = async () => {
  const token = localStorage.getItem("token");

  if (!token) return;

  // Token期限を取得
  const formatToken = KJUR.jws.JWS.parse(token);
  const tokenPalyload = formatToken.payloadObj as { exp: string };
  const tokenExp = Number(tokenPalyload.exp);
  const today = new Date();
  const todayUnix = Math.floor(today.getTime() / 1000); // ミリ秒は消す

  // Tokenの期限が大体30分超える場合はそのまま
  if (tokenExp - todayUnix > 1800) {
    return token;
  }
  // Tokenの期限が過ぎていた場合
  if (tokenExp - todayUnix < 0) {
    localStorage.removeItem("token");
    return;
  }
  // Tokenの期限が30分以内の場合
  const res = await client.mutate({ mutation: RefreshTokenDocument });
  const refreshToken = res.data.refreshToken.token;
  localStorage.setItem("token", refreshToken);
  return refreshToken;
};

export default refreshToken;
