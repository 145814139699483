import colors from "colors";
import React from "react";
import styled from "styled-components";

type Props = {
  size?: "small" | "default" | "large" | number;
  color?: "initial" | "textSecondary" | "green" | "white" | "error";
  fontWeight?: "initial" | "bold";
  display?: "block" | "inlineBlock";
};

const TextComponent: React.FC<Props> = ({
  size = "default",
  color = "initial",
  fontWeight = "initial",
  display = "block",
  children
}) => {
  return (
    <Text color={color} size={size} fontWeight={fontWeight} display={display}>
      {children}
    </Text>
  );
};

type InitializedProps = Required<Props>;

const Text = styled.div<InitializedProps>`
  font-size: ${({ size }) => {
    switch (size) {
      case "small":
        return "10px";
      case "large":
        return "18px";
      case "default":
        return "14px";
      default:
        return `${size}px`;
    }
  }};
  color: ${({ color }) => (color === "initial" ? colors.text : colors[color])};
  font-weight: ${({ fontWeight }) =>
    fontWeight === "initial" ? "initial" : "600"};
  display: ${({ display }) => display !== "block" && "inline-block"};
`;

export default TextComponent;
