import Dialog from "@material-ui/core/Dialog";
import Button from "components/Atoms/Button";
import Text from "components/Atoms/Text";
import React from "react";
import styled from "styled-components";

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

const ErrorDialog: React.FC<Props> = ({ isOpen, onClose }) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <Content>
        <img
          src={`${process.env.PUBLIC_URL}/images/error/error.svg`}
          alt="error"
        />
        <TextDiv>
          <Text size="large" color="green" fontWeight="bold">
            通信エラーが発生しました
          </Text>
        </TextDiv>
        <Text color="green">しばらくしてから</Text>
        <Text color="green">もう一度お試しください。</Text>
        <ButtonDiv>
          <Button onClick={onClose}>閉じる</Button>
        </ButtonDiv>
      </Content>
    </Dialog>
  );
};

const Content = styled.div`
  padding: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 208px;
`;

const TextDiv = styled.div`
  padding: 20px 0 10px 0;
`;

const ButtonDiv = styled.div`
  padding-top: 20px;
`;

export default ErrorDialog;
