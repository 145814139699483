import colors from "colors";
import React from "react";
import styled from "styled-components";

type Props = {
  onClick: (e: React.BaseSyntheticEvent) => void;
};

const ButtonComponent: React.FC<Props> = ({ onClick, children }) => {
  return <Button onClick={onClick}>{children}</Button>;
};

const Button = styled.button`
  min-width: 150px;
  height: 50px;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: ${colors.green};
  color: ${colors.white};
  border-radius: 25px;
  font-size: 16px;
`;

export default ButtonComponent;
