import colors from "colors";
import React from "react";
import styled from "styled-components";

type Props = {
  size?: "small" | "default" | "large" | number;
  color: "green" | "pink" | "white";
  fontWeight?: "initial" | "bold";
};

const TextComponent: React.FC<Props> = ({
  size = "default",
  color,
  fontWeight = "initial",
  children
}) => {
  return (
    <Text color={color} size={size} fontWeight={fontWeight}>
      {children}
    </Text>
  );
};

const Text = styled.div<Props>`
  font-family: ${({ fontWeight }) =>
    fontWeight === "initial" ? "RobotoCondensed" : "RobotoCondensed-Bold"};
  font-size: ${({ size }) => {
    switch (size) {
      case "small":
        return "20px";
      case "large":
        return "40px";
      case "default":
        return "32px";
      default:
        return `${size}px`;
    }
  }};
  color: ${({ color }) => colors[color]};
  font-weight: 600;
  text-align: center;
`;

export default TextComponent;
